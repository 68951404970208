button {
  cursor: pointer;
}

.w8Ntsa_audio {
  width: 100%;
}

.w8Ntsa_icon {
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 20px;
  display: flex;
  position: relative;
}

.w8Ntsa_icon svg {
  cursor: pointer;
  color: #3d5544;
  width: 15px;
  height: 15px;
  position: absolute;
}

.w8Ntsa_container {
  z-index: 10000;
  width: 100%;
  height: 100%;
}

.w8Ntsa_playlistWrapper {
  position: relative;
}

.w8Ntsa_bg {
  width: 100%;
  height: 100%;
}

.w8Ntsa_playlistContainer {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.w8Ntsa_group {
  text-align: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.w8Ntsa_title {
  color: #3d5544;
  padding-top: 8px;
  padding-bottom: 4px;
  font-family: Menlo, monospace;
  font-weight: 600;
}

.w8Ntsa_title text {
  font-family: Menlo, monospace;
}

.w8Ntsa_record {
  justify-content: flex-end;
  width: 100%;
  height: 93%;
  margin-top: 7%;
  margin-right: 7%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.w8Ntsa_record img {
  object-fit: contain;
  max-width: 12%;
  max-height: 12%;
}

.w8Ntsa_controlContainer {
  justify-content: space-around;
  gap: 2px;
  width: 45%;
  margin: 0 auto;
  display: flex;
}

.w8Ntsa_controlButton {
  text-align: center;
  color: #3d5544;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0000;
  border: 1.5px solid #3d5544;
  border-radius: 50px;
  font-family: Menlo, monospace;
}

.w8Ntsa_flash {
  animation: .5s infinite w8Ntsa_flash;
}

@keyframes w8Ntsa_flash {
  0%, 100% {
    border-color: #3d5544;
  }

  50% {
    border-color: #3d554400;
  }
}

.zPECKq_Ticker {
  white-space: nowrap;
  color: #fff;
  background-color: #000;
  width: 100%;
  height: 40px;
  padding: 10px 0;
  font-size: 12px;
  position: fixed;
  bottom: 40px;
}

.zPECKq_Ticker .zPECKq_segment {
  letter-spacing: 1px;
  padding-left: 25%;
  font-family: Menlo, monospace;
  font-weight: normal;
  animation: 5s linear infinite zPECKq_scroll;
  display: inline-block;
}

.zPECKq_Ticker.zPECKq_reverse .zPECKq_segment {
  animation-direction: reverse;
}

@keyframes zPECKq_scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.NYhbNW_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.NYhbNW_image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes NYhbNW_pulsate {
  0% {
    transform: translate(-50%)scale(1);
  }

  50% {
    transform: translate(-50%)scale(1.2);
  }

  100% {
    transform: translate(-50%)scale(1);
  }
}

.NYhbNW_ticketButton {
  cursor: pointer;
  color: #fff;
  background-color: #2563eb;
  border-radius: 8px;
  padding: 12px 20px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color .3s;
  animation: 2s ease-in-out infinite NYhbNW_pulsate;
  position: absolute;
  bottom: 15%;
  left: 50%;
}

.NYhbNW_ticketButton:hover {
  background-color: #3d5544;
}
/*# sourceMappingURL=index.d2e8dfc6.css.map */
